@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300&display=swap');

$fontSizeRegular: 16px;

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  color: var(--ui-theme-text-primary);
}

span {
  color: var(--ui-theme-text-primary);
}
.text-regular {
  font-style: normal;
  font-weight: 400;
}
.text-bold {
  font-style: normal;
  font-weight: 700;
}

label {
  color: var(--ui-theme-ui-label);
  letter-spacing: 0.25px;
  font-size: 0.75rem;
  cursor: inherit;
}

h1 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 32px;
}

h2 {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 26px;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
  line-height: 21px;
}

h4 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 19px;
}

h5 {
  font-size: 0.75rem;
  font-weight: bold;
}

h6 {
  font-size: 0.75rem;
  font-weight: 500;
}
