@use './colors.scss' as colors;
@use 'sass:map' as map;
@use './typography.scss';
@use '@angular/cdk/overlay-prebuilt';

@mixin color($color, $type: 'basic') {
  background-color: getColor($color, $type);
}

@function getColor($color, $type: 'basic') {
  $color-type: map-get(colors.$color-types, $type);

  @return var(--ui-theme-#{$color}#{$color-type});
}

@mixin themeBuilder($palette, $palette-name) {
  @if ($palette-name != '') {
    @each $name, $color in $palette {
      --ui-theme-#{$palette-name}-#{$name}: #{$color};
    }
  } @else {
    @each $name, $color in $palette {
      --ui-theme-#{$name}: #{$color};
    }
  }
}

@mixin themeTextBuilder($palette, $palette-name) {
  @each $name, $color in $palette {
    --ui-theme-#{$name}-text: #{$color};
  }
}

:root > .light {
  @include themeBuilder(colors.$light, '');
  @include themeBuilder(colors.$blue-palette, 'blue');
  @include themeBuilder(colors.$red-palette, 'red');
  @include themeBuilder(colors.$orange-palette, 'orange');
  @include themeBuilder(colors.$green-palette, 'green');
  @include themeBuilder(colors.$grey-palette, 'grey');
}

:root > .dark {
  @include themeBuilder(colors.$dark, '');
  @include themeBuilder(colors.$blue-palette, 'blue');
  @include themeBuilder(colors.$red-palette, 'red');
  @include themeBuilder(colors.$orange-palette, 'orange');
  @include themeBuilder(colors.$green-palette, 'green');
  @include themeBuilder(colors.$grey-palette, 'grey');
}
